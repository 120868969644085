div.section {
    &:first-of-type {
        margin-top: 30px;
    }
    margin-bottom: 30px;
    opacity: 1;

    &.hidden {
        opacity: 0;
    }

    &:not(.hidden) {
        animation-name: fadeIn;
        animation-duration: 100ms;
    }
}

.foo { flex: 1; }

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.float-right {
    float: right;
}

body {
    margin: unset;
}

.navbar > div {
    display: flex;
    flex-direction: column;
    margin: 5px;
}

.beta-badge {
    align-items: center;
    padding-left: 30px;
    padding-top: 10px;
}

#tenantSelector {
    margin-right: 15%;
}

@import "~bootstrap/scss/bootstrap";