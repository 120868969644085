div.dnd-outer-div {
  position: relative;
  .dnd-inner-div-1 {
    border: 3px dashed #879596;
    background-color: #fafafa;
    opacity: 0.9;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;

    .dnd-inner-div-2 {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      left: 0;
      text-align: center;
      color: #687078;
      font-size: large;
    }
  }
}